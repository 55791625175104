import React from 'react'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Timeline from '../../../../components/ui/timeline-ru'
import ImageOne from '../../../../data/images/bg/materials/cleaning/pcb/us-clean-500x350.jpg'
import ImageTwo from '../../../../data/images/bg/materials/cleaning/pcb/spray-clean-500x350.jpg'
import ImageThree from '../../../../data/images/bg/materials/cleaning/pcb/manual-clean-500x350.jpg'
import { SectionWrap } from './style'


const TimelineArea = (props) => {
    const content = [
        {
            year: "УЛЬТРАЗВУК",
            image: ImageOne,
            title: "Жидкости для ультразвуковой отмывки плат",
            desc: "Моющие жидкости специально разработанные для мойки собранных печатных плат при помощи ультразвуковой кавитации, которая используется как механическое воздействие на поверхность печатных плат. Очистка плат происходит в водном растворе с применением технологии MPC® (Micro Phase Cleaning, «микрофазовая очистка»)  разработанной немецкой компанией ZESTRON. Особенностью технологии MPC® в том, что она сочетает в себе преимущества традиционных растворителей и поверхностно-активных веществ, при отсутствии  их недостатков.",
            path: "/материалы-для-smt/жидкости-для-мойки/жидкости-для-отмывки-печатных-плат/для-ультразвуковой-отмывки-плат",
            lang: "Подробно..."
        },
        {
            year: "СТРУЙНАЯ МОЙКА",
            image: ImageTwo,
            title: "Жидкости для струйной отмывки плат",
            desc: "Струйная автоматическая мойка – это современная прогрессивная технология для очистки электронных сборок. Моющие жидкости для струйной отмывки применяют технологию MPC® (Micro Phase Cleaning, «микрофазовая очистка»), что обеспечивает большой срок жизни раствора. Моющий раствор выдерживает большое число циклов и регенерируется методом обычной механической фильтрации раствора, что обеспечивает минимальную стоимость процесса очистки плат.",
            path: "/материалы-для-smt/жидкости-для-мойки/жидкости-для-отмывки-печатных-плат/жидкость-для-струйной-отмывки-плат",
            lang: "Подробно..."
        },
        {
            year: "РУЧНАЯ ОЧИСТКА",
            image: ImageThree,
            title: "Ручная очистка плат",
            desc: "Ручная очистка плат применяется при производстве прототипов, ремонтных работах и в мелкосерийном производстве. Обычно для это используют жидкости на основе растворителей. Моющие жидкости на основе органических растворителей универсальны и растворяют большинство загрязнений возникающих во время пайки. Основной минус – это низкая температура вспышки, что требует соблюдения специальных норм безопасности и токсичность.",
            path: "/материалы-для-smt/жидкости-для-мойки/жидкости-для-отмывки-печатных-плат/жидкость-для-ручной-отмывки-плат",
            lang: "Подробно..."
        }
    ]
    const contentua = [
        {
            year: "УЛЬТРАЗВУК",
            image: ImageOne,
            title: "Рідини для ультразвукового відмивання плат",
            desc: "Миючі рідини спеціально розроблені для миття складених друкованих плат за допомогою ультразвукової кавітації, яка використовується як механічна дія на поверхню друкованих плат. Очищення плат відбувається у водному розчині із застосуванням технології MPC® (Micro Phase Cleaning, мікрофазове очищення) розробленої німецькою компанією ZESTRON. Особливістю технології MPC® у тому, що вона поєднує у собі переваги традиційних розчинників та поверхнево-активних речовин, за відсутності їх недоліків.",
            path: "/ua/матеріали-для-smt/рідини-для-чищення/рідини-для-відмивання-друкованих-плат/для-ультразвукового-миття",
            lang: "Докладно..."
        },
        {
            year: "СТРУМЕНЕВЕ МИТТЯ",
            image: ImageTwo,
            title: "Рідини для струменевого відмивання плат",
            desc: "Струменеве автоматичне миття – це сучасна прогресивна технологія для очищення електронних модулів. Миючі рідини для струменевого відмивання застосовують технологію MPC® (Micro Phase Cleaning, мікрофазове очищення), що забезпечує великий термін життя розчину. Миючий розчин витримує велику кількість циклів та регенерується методом звичайної механічної фільтрації розчину, що забезпечує мінімальну вартість процесу очищення плат.",
            path: "/ua/матеріали-для-smt/рідини-для-чищення/рідини-для-відмивання-друкованих-плат/для-струменевого-миття-плат",
            lang: "Докладно..."
        },
        {
            year: "РУЧНЕ ОЧИЩЕННЯ",
            image: ImageThree,
            title: "Ручне очищення плат",
            desc: "Ручне очищення плат застосовується при виробництві прототипів, ремонтних роботах та у дрібносерійному виробництві. Зазвичай для цього використовують рідини на основі розчинників. Миючі рідини на основі органічних розчинників універсальні і розчиняють більшість забруднень, що виникають під час паяння. Основний мінус – це низька температура спалаху, що потребує дотримання спеціальних норм безпеки та токсичность.",
            path: "/ua/матеріали-для-smt/рідини-для-чищення/рідини-для-відмивання-друкованих-плат/рідина-для-ручного-миття-плат",
            lang: "Докладно..."
        }
    ]
    if (props.lang === "ru") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Жидкости для отмывки печатных плат – применяют для удаления загрязнений с печатных узлов после пайки в печи и пайки волной припоя. Применяют для изделий повышенной надежности, которые будут эксплуатироваться в условиях повышенных температур и влажности, изделия которые будут покрываться защитным покрытием. По типу применения жидкости для отмывки печатных плат делятся на: жидкости для ультразвуковой отмывки, жидкости для струйной отмывки. Отмывка печатных плат в моющей среде с механическим воздействием требует дальнейших операций: черновое полоскание, чистовое полоскание деионизированной водой с замкнутой системой деионизации, сушка.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={content} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    } else if (props.lang === "uk") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Рідини для відмивання друкованих плат – застосовують для видалення забруднень з друкованих вузлів після паяння в печі та паяння хвилею припою. Застосовують для виробів підвищеної надійності, які експлуатуватимуться в умовах підвищених температур та вологості та вироби, які будуть покриватися захисним покриттям. За типом застосування рідини для відмивання друкованих плат діляться на: рідини для ультразвукового відмивання, рідини для струменевого відмивання. Відмивання друкованих плат у миючому середовищі з механічним впливом потребує подальших операцій: попереднє полоскання, фінальне полоскання деіонізованою водою із замкнутою системою деіонізації, сушіння.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={contentua} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    }
}

export default TimelineArea
